import doctor1 from "./img/doctores/dr_1.png";
import doctor2 from "./img/doctores/dr_2.png";
import doctor3 from "./img/doctores/dr_3.png";
import doctor4 from "./img/doctores/dr_4.png";
import doctor5 from "./img/doctores/dr_5.png";
import doctor6 from "./img/doctores/dr_6.png";
import doctor7 from "./img/doctores/dr_7.png";
import doctor8 from "./img/doctores/dr_8.png";
import doctor9 from "./img/doctores/dr_9.png";




export default{  //objeto
    "doc1":doctor1,
    "doc2":doctor2,
    "doc3":doctor3,
    "doc4":doctor4,
    "doc5":doctor5,
    "doc6":doctor6,
    "doc7":doctor7,
    "doc8":doctor8,
    "doc9":doctor9
}