import './App.css';
/* import React, { useState } from 'react'; */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; /* npm install bootstrap */
import "bootstrap-icons/font/bootstrap-icons.css"; /* npm install bootstrap-icons  */
import 'bootstrap';

/* npm install --save reactstrap react react-dom */

import { FloatingWhatsApp } from 'react-floating-whatsapp'; /* npm install react-floating-whatsapp  */
import  img  from './assets/doctores';

import Mapa  from './componentes/Mapa'
import Tratamientos  from './componentes/Tratamientos'
import Footer from './componentes/Footer'

import NuestrosProfesionales  from './componentes/Profesionales'
/* import Instalaciones  from './componentes/Instalaciones' */


function App() {
  return (
    <div className="App">
      
        

        <div id="topbar" className="d-flex justify-content-center fixed-top">{/* d-flex para crear un contenedor flexible, transformando elementos hijos directos en elementos flexibles */}
                                    {/* justify-content-center define cómo el navegador distribuye el espacio entre y alrededor de los items flex, a lo largo del eje principal de su contenido */}
                                    {/* selector topbar centrado en la pagina y fijado en top y dentro esta la caja container con el  justify-content-between */}
            <div className="container d-flex justify-content-between">             
                  <div className="contacto-info d-flex align-items-center">
                    <i className="bi bi-envelope-fill"></i> <a href="mailto:contact@example.com">info@ceotobalaba.com</a>
                    <a href="https://wa.me/56933678918"> <div></div><i className="bi bi-whatsapp"></i> </a> 
                    <a href="https://wa.me/56933678918">  +569 33 67 89 18</a>  {/* la etiqueta <i> es para mostrar en recursiva */}
                  </div>
                  
                  <div className="d-none d-lg-flex social-links align-items-center">
                    <a href="https://www.instagram.com/somosceotobalaba/" className="twitter"><i className="bi bi-twitter"></i></a>
                    <a href="https://www.instagram.com/somosceotobalaba/" className="facebook"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/somosceotobalabs/" className="instagram"><i className="bi bi-instagram"></i></a>
                    <a href="#https://www.instagram.com/somosceotobalaba/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                  </div>
            </div>     
        </div>



        {/*<!-- ======= Header ======= --> */}              {/* <div className="clearfix"></div> */}
        <header id="header" className="fixed-top">
          

          <div className="container d-flex align-items-center">

            <h1 className="logo me-auto " ><a href="index.html" >Ceotobalaba</a></h1>

          
            {/*         <!-- Uncomment below if you prefer to use an image logo -->
            <!-- <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>--> */}
            <nav className="navbar navbar-expand-lg bg-light">
              <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span> 
                </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                          <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="index.html">Inicio</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link scrollto" href="#tratamientos">Tratamientos</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="#profecionales">Profesionales</a>
                          </li>
                          {/*<li className="nav-item">
                            <a className="nav-link" href="index.html">Quienes Somos</a>
                          </li> */}
                            <li className="nav-item">
                            <a className="nav-link" href="#contacto">Contacto</a>
                          </li>
                        </ul>
                    </div>
                </div>
            </nav>





            
            <a href="http://agendamiento.softwaredentalink.com/agendas/online/79a2f87dc226b396c36a958b18fc63180b820fb4" className="btn btn-primary"><span className="d-none d-md-inline">Agendemos tu </span>cita</a>
          </div>
        </header>{/* <!-- End Header --> */}
        

        
        <div id="hero" className="d-flex align-items-center">
          <div className="container">
            <h1>Somos </h1>
            <h1>Ceotobalaba</h1>
              
            <a href="http://agendamiento.softwaredentalink.com/agendas/online/79a2f87dc226b396c36a958b18fc63180b820fb4" className="btn-get-started scrollto">Agendar Cita</a>
          </div>
        </div>{/* <!-- End Hero --> */}

  



    <div id = "tratamientos">
      <Tratamientos/>
    </div>
       
    
    <div id = "profecionales">
      <NuestrosProfesionales/>
    </div>
     

     {/*  <Instalaciones/> */}

      <Mapa/>

     {/* <!-- ======= contactar ======= --> */}

    <div id ="contacto">

      <Footer/>
    </div>
     


   <FloatingWhatsApp phoneNumber='56933678918'  chatMessage='Estamos para ayudarles' avatar={img.doc7} accountName='Alejandra' />
    </div>
  );
}

export default App;
