import './css_comp/AppCompo.css';
/* import React, { useState } from 'react'; */
import React from 'react';


function Mapa_a_mostrar() {
    return (
        
        <div id = "Mapa">
            <iframe style={  {"border": 0, "width":"100%", "height": "350px"} } // el estilo se debe manejar como objeto por estar dentro de react
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3330.1518593051646!2d-70.6022841!3d-33.419285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf6b041d833f%3A0x71835d24bad4274c!2sTobalaba+155%2C+Providencia%2C+Regi%C3%B3n+Metropolitana%2C+Chile!5e0!3m2!1ses!2ses!4v1484189686833" 
            >

        </iframe>

        
        </div>

    );

}
export default Mapa_a_mostrar