/* import './App.css'; */
import './css_comp/profesi.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; /* npm install bootstrap */
import "bootstrap-icons/font/bootstrap-icons.css"; /* npm install bootstrap-icons  */

import  img  from '../assets/doctores';



function Personal_a_mostrar() {
  return (

      <div id="doctors" className="doctors">
        <div className="container">

          <div className="doctors-title">
            <h2>Nuestros Profesionales</h2>

          </div>

          <div className="row">
  
            <div className="col-lg-6">
                <div className="member d-flex align-items-start">
                  <div className="pic"><img src={img.doc1}className="img-fluid" alt=""/></div>
                  <div className="member-info">
                    <h4>Dra. María Teresa Campos</h4>
                    <span>Ortodoncia</span>
                    <p></p>
                    <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                    </div>
                  </div>
                </div>
              </div>




            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc2} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dra. Alba Herrera</h4>
                  <span>Rehabilitación y Estética</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc3} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dra. Martha Rosales</h4>
                  <span>Rehabilitación y Estética</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc4} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dra. Orcelys Mendez</h4>
                  <span>Cirugia</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc5} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dra. Daniela Araya</h4>
                  <span>Odontología General</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>

{/*             <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc8} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dr. Martin Sarabia</h4>
                  <span>Odontologia General</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>
 */}

{/*             <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc9} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Dra karina Zambrano</h4>
                  <span>Endodoncia</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div> 
 */}
            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc6} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Ariangela Marcano</h4>
                  <span>Asistente Dental</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mt-4">
              <div className="member d-flex align-items-start">
                <div className="pic"><img src={img.doc7} className="img-fluid" alt=""/></div>
                <div className="member-info">
                  <h4>Alejandra Borges</h4>
                  <span>Asistente Administrativo</span>
                  <p></p>
                  <div className="social">
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-twitter"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-facebook"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"><i className="bi bi-instagram"></i></a>
                      <a href="https://www.instagram.com/somosceotobalaba/"> <i className="bi bi-linkedin"></i> </a>
                  </div>
                </div>
              </div>
            </div> 


          </div>
        </div>
      </div>


  );
}

export default Personal_a_mostrar;
