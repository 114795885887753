import './css_comp/tratamientos.css';
/* import React, { useState } from 'react'; */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; /* npm install bootstrap */
import "bootstrap-icons/font/bootstrap-icons.css"; /* npm install bootstrap-icons  */
import  img  from '../assets/tratamientos';

function Tratamientos_a_mostrar() {
    return (
        <div id="servicio" className="servicio">

            <div className="servicio-title">
                    <h2>Tratamientos</h2>
            </div>

            <div className="row">
                {/* (12 columnas agrupadas de a 3-> 12/3 = 4 ) 4 en grande ,  (12columnas/4)3  en mediano 2 en pequeño 1 en muy pequeño */}
                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">{/* grillas */}
                    <div className="list1">
                    
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">ODONTOLOGIA <br/>GENERAL<span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/>
                        
                    </div>          
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">{/* grillas */}
                    <div className="list1">
                    
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">ORTODONCIA<br/><br/><span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/><br/>
                        
                    </div>          
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">
                    <div className="list1 ">
                    
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">CIRUGÍA BUCAL<br/><br/><span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/><br/>
                        
                    </div>
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">
                    <div className="list1 ">
 
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">PERIODONCIA<br/><br/><span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/><br/>
                        
                    </div>
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">
                    <div className="list1 ">
 
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">REHABILITACION <br/>BUCAL<span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/>
                        
                    </div>
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">
                    <div className="list1 ">
 
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">ENDODONCIA<br/><br/><span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/><br/>
                        
                    </div>
                </div>

                <div className="col-lg-3 col-md-4  col-sm-6  col-xs-6">
                    <div className="list1 ">
 
                            <div className="img-circle">
                                <img src={img.page1_icon5} className="img-fluid" alt=""/><span></span>
                            </div>

                            <p class="listtitle1">ODONTOPEDIATRIA<br/><br/><span>{/* Dolore ipsum */}</span></p>
                            <p>{/* texto */}</p>
                            <a href="index.html" class="btn btn-primary btn2">Mas Información</a><br/><br/><br/>
                        
                    </div>
                </div>

            </div>                         
        </div>
        
    );

}
export default Tratamientos_a_mostrar
