import './css_comp/footer.css';
/* import React, { useState } from 'react'; */
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'; /* npm install bootstrap */
import "bootstrap-icons/font/bootstrap-icons.css"; /* npm install bootstrap-icons  */

function Footer_para_mostrar() {
  return (
  
      <footer id="footer">

              <div className="footer-top">
                <div className="container">
                  <div className="row"> {/* fila */}

                    <div className="col-lg-3 col-md-6 footer-contact">
                      <h3>Ceotobalaba</h3>
                      <p>
                        Ofi: 605, Piso 6<br/>
                        Tobalaba 155, Providencia<br/>
                        
                        Chile <br/><br/>
                        <strong>Phone:</strong> <i className="bi bi-phone "></i>+562 33392057<br/>
                        <strong>Email:</strong>  <i className="bi bi-envelope-fill"></i> info@ceotobalaba.com<br/>
                        <strong>Whatsapp: </strong> <i className="bi bi-whatsapp "> </i> <a   href="https://wa.me/56933678918">+569 33678918 </a> <br/>
                      </p>
                    </div>

                  </div>
                </div>
              </div>



            <div className="container d-md-flex py-4">

            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                &copy; Copyright <strong><span>arobertis</span></strong>. All Rights Reserved
              </div>
              <div class="credits">
              Designed by Armando Robertis
              </div>


              </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                  <a href="https://www.instagram.com/somosceotobalaba/" className="twitter"><i className="bi bi-twitter"></i></a>
                  <a href="https://www.instagram.com/somosceotobalaba/" className="facebook"><i className="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/somosceotobalaba/" className="instagram"><i className="bi bi-instagram"></i></a>
                  <a href="https://www.instagram.com/somosceotobalaba/" className="google-plus"><i className="bi bi-skype"></i></a>
                  <a href="https://www.instagram.com/somosceotobalaba/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                  {/* <i class="bi bi-arrow-up-short"></i> */}
                </div>
              </div>

      </footer>


   




      

  );
}

export default Footer_para_mostrar;
